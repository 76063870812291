<template>
  <div v-if="client">
    <h1 class="text-h1 mb-3">{{ client.name }}</h1>
    <v-card>
      <v-card-text>
        {{ $t('client.fields.name') }} : {{ client.name }}
      </v-card-text>
    </v-card>
    <v-tabs class="my-3" background-color="transparent">
      <v-tab exact :to="{name: 'client.connectors'}" v-if="can('client.connector.read')">{{ $tc('connector.title', 2) }}</v-tab>
      <v-tab exact :to="{name: 'client.products'}" v-if="can('client.product.read')">{{ $tc('product.title', 2) }}</v-tab>
      <v-tab exact :to="{name: 'client.countries'}" v-if="can('client.country.read')">{{ $t('client.countries') }}</v-tab>
      <v-tab exact :to="{name: 'client.anonymize'}" v-if="can('client.anonymize.read')">{{ $t('client.anonymize') }}</v-tab>
      <v-tab exact :to="{name: 'client.webhook'}" v-if="can('client.webhook.read')">{{ $t('webhook.title') }}</v-tab>
      <v-tab exact :to="{name: 'client.webhook-log'}" v-if="can('client.webhook.read')">{{ $t('webhook.logTitle') }}</v-tab>
      <v-tab exact :to="{name: 'client.particularities'}" v-if="can('client.read')">{{ $t('client.particularities.title') }}</v-tab>
    </v-tabs>

    <RouterView :client="client"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'ClientLayout',
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  computed:{
    ...mapGetters("profile", ["can"]),
  }
};
</script>
